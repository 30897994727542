// HEADER


.page-header { 				// header strip holding logo and show nav button
	position: fixed;
	width: 100%;
	background: $white;
	padding: $gutterwidth;
	z-index: 10;
	top: 0;
	min-height: 120px;
	@include transition(all 0.6s ease-in-out 0.6s);
	.header-nav {
		display: none;
	}

	@include mq(tab) {
		background: transparent;
		width: 33%;
		.header-nav { 
			display: inline-block;
		}
		.show-nav-button {
			display: none;
		}
	}
}

.logo-block {
	width: 40%;
	max-width: 80px;
	display: inline-block;
	float: left;

	a {
		width: 100%;
		height: 100%;
		display: inline-block;
	}
	@include mq(tab) {
		position: fixed;
		max-width: 90px;
		margin-left: $gutterwidth*2;
	}
	@include mq(lap) {
		max-width: 95px;
		margin-left: $gutterwidth*3;
	}
	@include mq(desk) {
		margin-left: $gutterwidth*4;
	}
	@include mq(epic) {
		margin-left: $gutterwidth*5;
	}
}

.show-nav-button {
	position: absolute;
	top: 0;
	right: 0;
	padding: 15px $gutterwidth;
	font-weight: $bold;
	@include font-size(18);
	cursor: pointer;
	span {
		opacity: 0.0;
		@include transition();
		right: -3px;
		position: relative;
	}

	@include mq(tab) {
	    left: 25px;
	    top: 20px;
	    padding: 15px 25px;
	}
	@include mq(lap) {
	    left: 45px;
	}
	@include mq(epic) {
	    left: 35px;
	}
}

h1 {				// h1 page title
	position: relative;
//	top: 34px;
//	text-align: right;
//	width: calc(100% - 80px);
	@include font-size(18);
	display: inline-block;
//	padding-left: 20px;
	margin-bottom: 0.15em;
	.open-link {
//		display: none;
	}
	a .open-link {
		display: inline-block;
		&:before {
			background-color: #333 !important;
		}
	}

	@include mq(tab) {
		text-align: left;
//		position: fixed;
//		top: 160px;
		@include font-size(18);
//		max-width: 21%;
		a .open-link {
			display: inline-block;
			&:before {
				background-color: #333 !important;
			}
		}
	}
	@include mq(lap) {
//		margin-left: $gutterwidth*2;
//		top: 200px;
//		max-width: 21%;
		@include font-size(24);
		.open-link:before {
			width: 20px;
			height: 2px;
		}
	}
}

.page-body {
	h1 a {
		color: $midgrey;
	}
	h2 {
		margin-bottom: 1.0em;
	}
	&.push-top {
		margin-top: 160px;
		
		.page-header {
			margin-top: 94px;
		}
		@include mq(tab) {
			margin-top: 73px;
			.site-jump {
				span {
					width: 49%;
				}
				.align-right {
					text-align: right;
				}
			}
		}
		@include mq(lap) {
			margin-top: 72px;
			
			.full-nav {
				top: 72px;
			}
		}
		.site-jump {
			display: inline-block;
		}
	}
}



.site-jump {
	background: #191919 url(../img/site-jump.png) left center no-repeat;
    background-size: cover;
	color: $white;
	padding: 25px 25px;
	display: none;
	width: 100%;
	position: relative;
	margin: 0px auto 0;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	box-sizing: 	border-box;
	span {
		color: $white;
		display: inline-block;
		width: 100%;
		@include transition();
		font-size: 90%;
	}
	.open-link:before {
		background-color: $white;
	}
	@include mq(lap) {
	    background-size: contain;
	    span {
			font-size: 100%;	    
	    }
	}
	
	&:hover span.align-right {
		color: $red;
	}
}



