// LISTS

.grid-links {
	@include listreset();
	
	img {
		margin: 0 0 10px 0;
	}
	h2, h3 {
		margin-bottom: 0.5em;
	}
	h2, h3, p {
		max-width: 400px;
	}
	@include mq(phab) {
		margin: 0 -2%;
		li {
			width: 45%;
			margin: 2%;
			display: inline-block;
			vertical-align: top;
		}
	}
	li {
		&:before {
//			content: "";
			
		}
	}
}


.text-content {
	.grid-links {
		li {
			padding-left: 0;
			&:before {
				display: none;
			}
			a, h3 {
				color: $textcolor;
				@include transition();
			}
			a:hover {
				h3 {
					color: $red;
				}				
			}
		}
	}
}
