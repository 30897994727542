/* ============================================================

Cone: A Marshall

============================================================ */

/* Buttons =================================================== */

.btn {
	display: inline-block;
	*display: inline;
	/* IE7 inline-block hack */
	*zoom: 1;
	padding: 6px 11px 6px;
	margin-bottom: 0;
	font: {
		weight: $regular;
		family: $basefont;
	}
	@include font-size(14);
	line-height: $baselineheight;
	color: $buttoncolor;
	text-align: center;
	vertical-align: middle;
	background-color: $grey;
	border: $buttonborderwidth $buttonborderstyle $buttonbordercolor;
	@include border-radius(3px);
	cursor: pointer;
	*margin-left: .3em;

	&:visited,
	&:hover {
	    color: $textcolor;
		text-decoration: none;
	}
}


.btn:first-child { *margin-left: 0; }

// disabled

.btn-disabled {
	@include opacity(.5);
	cursor: default;
}

.btn-error {
	@include errorgrad;
	color: $white;

	&:visited,
	&:hover { 
		color: $white; 
	}
}


// primary

.btn-primary {
	background-color: $basecolor;
}

// success

.btn-success {
	background-color: $successcolor;
}

// warning

.btn-warning {
	background-color: $warningcolor;
}

// sizes

.btn-mini {
	padding: 3px 5px;
	@include font-size(11);
	line-height: 14px;
}

.btn-small {
	padding: 6px 10px;
	@include font-size(11);
	line-height: 16px;
}

.btn-large {
	padding: 11px 22px;
	@include font-size(14);
	line-height: normal;
	@include border-radius(4px);
}

.btn-extlarge {
	padding: 11px 22px;
	@include font-size(21);
	@include border-radius(4px);
}

.btn-group {
	position: relative;
	*zoom: 1;
	*margin-left: .3em;
	margin-bottom: $baseline;

	.btn {
    	position: relative;
		float: left;
		margin-left: -1px;
		@include border-radius(0);

		&:first-child {
			margin-left: 0;
			@include border-radius(2px, 0, 2px, 0);
    	}

		&:last-child {
			@include border-radius(0, 2px, 0, 2px); 
		}

		&.large {
			&:first-child {
			margin-left: 0;
			@include border-radius(4px, 0, 4px, 0);
		}
		
		&:last-child { 
			@include border-radius(0, 4px, 0, 4px); 
		}
    }
  }
}

.btn-toolbar {
	clear: both;
	margin: {
	    top: $baselineheight / 2;
		bottom: $baselineheight / 2;
	}	

	.btn-group {
	    display: inline-block;
		*display: inline;
		*zoom: 1;
	}
}