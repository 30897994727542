// ICOMOON

@font-face {
	font-family: 'icomoon';
	src:url('/_assets/fonts/icomoon/icomoon.eot?-rt8vu1');
	src:url('/_assets/fonts/icomoon/icomoon.eot?#iefix-rt8vu1') format('embedded-opentype'),
		url('/_assets/fonts/icomoon/icomoon.woff?-rt8vu1') format('woff'),
		url('/_assets/fonts/icomoon/icomoon.ttf?-rt8vu1') format('truetype'),
		url('/_assets/fonts/icomoon/icomoon.svg?-rt8vu1#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-mobile:before {
	content: "\e600";
}
.icon-heart:before {
	content: "\e601";
}
.icon-heart2:before {
	content: "\e602";
}
.icon-earth:before {
	content: "\e603";
}
.icon-list:before {
	content: "\e604";
}
.icon-arrow-left:before {
	content: "\e605";
}
.icon-arrow-down:before {
	content: "\e606";
}
.icon-arrow-up:before {
	content: "\e607";
}
.icon-arrow-right:before {
	content: "\e608";
}
.icon-twitter:before {
	content: "\e609";
}
.icon-facebook:before {
	content: "\e60a";
}
.icon-googleplus:before {
	content: "\e60b";
}
.icon-instagram:before {
	content: "\e60c";
}
