// NAV

.full-nav { 				// Contains entire main navigation
	position: fixed;
	background-color: transparent;
	top: 0;
	right: -400px;
	width: 100%;
	height: 100%;
	opacity: 0.0;
	z-index: -1;
	@include transition(all 0.3s ease-in-out 0.0s);
	
	@include mq(tab) {
		width: calc(60% + 45px);
	}
	@include mq(lap) {
		width: calc(50% + 68px);
	}
	@include mq(desk) {
		width: calc(51% + 56px);
	}
	
	&:before {
		width: 5px;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0px;
		content: "";
		background-color: rgba($grey, 0.2);
		display: inline-block;
		opacity: 0.0;
		@include transition();
		
	}

	.footer-text {
		p {
			margin-top:20px;
			font-size: 1.1rem;
		}
	}
	
}

.header-nav { 				// Adds control to the main nav placement
	padding: $gutterwidth;
	padding-top: 120px;
	background: $white;
	overflow-y: scroll;
	height: 100%;
//	opacity: 0.0;
	@include transition(all 0.6s ease-in-out 0.0s);
	ul {
		@include listreset();
	}
	
	.secondary-nav { 				// contact / about links attop of page
		display: none;
	}
	@include mq(tab) {
		opacity: 1.0;
		padding: $gutterwidth*3 $gutterwidth*2;
	    margin-left: 5px;
	}
	@include mq(lap) {
		padding: $gutterwidth*4 $gutterwidth*3;
	}
}


.primary-nav { 				// colourful links to main sections of site, reused within the content
	@include listreset();
	> li {
		border-bottom-width: 1px;
		border-bottom-style: solid;
		padding: 18px 1px;
		.open-collapse {
		}
		&.inc-sub-nav .open-collapse {
			margin-right: -20px;
			float: right;
		}
		.open-link {
			margin-right: -6px;
			float: right;
		}
		&.inc-sub-nav {
			padding: 18px 1px 5px;
			padding-right: 20px;
		}
	}
	a {
//		font-weight: $bold;
		display: inline-block;
		width: 100%;
	}
	.subnav-toggle {
		display: inline-block;
		width: 100%;
		position: relative;
		margin-top: -5px;
		top: -10px;
		z-index: 2;
		&:before,
		&:after {
			@include font-size(13);
			position: absolute;
			left: 0;
			@include transition(all 0.6s ease-in-out 0.3s);
		}
		&:before {
			content: "Expand";			
		}
		&:after {
			content: "Minimise";	
			opacity: 0;		
		}
	}
	.sub-nav {
		display: inline-block;
		margin-top: -400px;
		position: relative;
//		@include translate(0px, -300px);
		@include transition(all 0.6s ease-in-out 0.3s);
		max-height: 0;
		margin-bottom: 15px;
		z-index: -1;
		li {
			@include transition(all 0.3s ease-in-out 0.0s);
			opacity: 0.0;
		}
		a {
			color: $textcolor;
			@include font-size(13);
			padding: 5px 0;
		}
	}
	.show-sub-nav {
		.subnav-toggle {
			&:before {
				opacity: 0;
			}
			&:after {
				opacity: 1.0;
			}
		}
		.sub-nav {
			display: inline-block;
			margin-top: 5px;
//			@include translate(0px, 0px);
			@include transition(all 0.6s ease-in-out 0.3s);
			max-height: 600px;
			z-index: 1;
			li {
				@include transition(all 0.6s ease-in-out 0.9s);
				opacity: 1.0;			
			}
		}
	}
	
	
	@include mq(tab) {
		margin-right: -4%;
		> li {
			width: 45%;
			display: inline-block;
			margin-right: 4%;
			margin-top: 20px;
			vertical-align: top;
			a {
				padding-right: 25px;
				box-sizing: border-box;
				.open-link {
					margin-right: -31px;
				}
			}
		}		
	}
	
	@include mq(desk) {
		margin-right: -4%;
		> li {
			margin-right: 2%;
			&.inc-sub-nav {
				padding-right: 0px;
				.open-collapse {
					margin-right: 0;
				}
			}
		}
	}
	@include mq(epic) {
		margin-right: -50px;
		> li {
			width: 22%;
			margin-right: 20px;
		}
	}
	
	
}


.footer-nav {
	padding: $gutterwidth 0;
	ul {
		margin: 0 0 20px 0;
		li {
			padding-left: 30px;
			@include font-size(14);
			margin: 0 0 0px 0;
			line-height: 25px;
		}
		a {
			color: $textcolor;
		}
	}
	.fa {
		margin: 0 10px 0 -20px ;
		width: 20px;

	}
	@include mq(tab) {
		margin-right: -4%;
		padding-top: $gutterwidth;
		ul {
			width: 45%;
			display: inline-block;
			margin-right: 4%;
			vertical-align: top;
			&.address li {
				padding-left: 0;
			}
		}
	}
	@include mq(lap) {
		padding-top: $gutterwidth*2;
	}
	@include mq(epic) {
		margin-right: -50px;
		padding-top: $gutterwidth*3;
		ul {
			width: 22%;
			margin-right: 20px;
			&.contact li {
				padding-left: 0;
			}
		}
	}
}

.full-page {
	.main-col {
		background-color: #ffffff;
		@include transition(background-color 0.6s ease-in-out 0.0s);
		> * {
			@include transition(all 0.6s ease-in-out 0.0s);
		}
	}
}



//// STYLES FOR HEADER & NAVIGATION WHEN VISIBLE


.page-body {
	&.show-nav {

		.full-nav {
			opacity: 1.0;
			z-index: 999;
			right: 0;
			@include transition(all 0.3s ease-in-out 0.0s);
			
			&:before {
				opacity: 1.0;
				@include transition(all 0.6s ease-in-out 0.0s);
				
			}
		}

		.header-nav { 		
			background: $offwhite;
			opacity: 1.0;
			@include transition(all 0.3s ease-in-out 0.0s);
		}

		@include mq($to: tab) {			
			.page-header { 				// header strip holding logo and show nav button
				background: $lightergrey;
				@include transition(all 0.6s ease-in-out 0.0s);
			}
			.show-nav-button {
				width: calc(100% - 5px);
				text-align: right;
				background: #f0f0f1;
				z-index: 10;
				@include transition();
			}
		}

		.show-nav-button {
			span {
				right: 0;
			}
			.close {
				opacity: 1.0;
				@include transition(all 0.3s ease-in-out 0.6s);
			}
		}
		
		.full-page {
			.main-col {
				background-color: #e5e5e5;
				@include transition(all 0.3s ease-in-out 0.0s);
				
				> * {
					opacity: 0.5;
					@include transition(all 0.3s ease-in-out 0.0s);
				}
			}
		}
			
	}
}







