// Images, Video, Figures


// img

img {
max-width : 100%;
border-width : 0;
vertical-align : middle;
-ms-interpolation-mode : bicubic; }

svg:not(:root) {
overflow : hidden; }

// figure

figure {
	margin-bottom : $baselineheight;

	img {
		margin-bottom : $baselineheight / 4; 
	}

	figcaption {
		display : block;
		font-weight : normal;
		@include font-size(14);
		color : $grey; 
	}
}

