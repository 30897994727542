// CONTACT FORM

.page-footer {
	border-top: 1px solid $lightgrey;
	padding-top: 20px;
	margin-top: 20px;
	
	h3 {
		font-weight: 500;
		margin-bottom: 10px;
	}
	.contact-form {
		max-width: 400px;
		.field,
		.btn {
			border: none;
			background: #d3d3d3;
			padding: 0px 10px;
			@include rounded(0);
			height: 36px;
			line-height: 36px;
		}
		.field {
			width: 100%;
			&.select {
			    -webkit-appearance: none;
			}			
		}
		.btn {
			padding: 0 0px;
			color: $red;
			background: $white none;
			@include transition();
			
			&:hover {
				color: $textcolor;
			}
		}
	}
	
	
	
	p {
		@include font-size(13);
		color: $lightgrey;
		max-width: 400px;
	}
	
	@include mq(lap) {
		border-top: 1px solid $red;
//		border-top: none;
		padding-top: 0px;
		background: url(../img/stencils/stencil-bg.jpg) right bottom no-repeat;
		margin: 30px -40px -21px -60px;
		padding: 28px 40px 10px 60px;
		.contact-form {
			display: inline-block;
			width: 100%;
			max-width: none;
			max-width: 600px;
		}
	}
	
	@include mq(desk) {
		padding-top: 0px;
		background: url(../img/stencils/stencil-bg.jpg) right bottom no-repeat;
		margin: 30px -40px -21px -60px;
		padding: 18px 40px 0px 60px;

		.contact-form {
			padding-top: 20px;
			display: inline-block;
		}
		
		p {
			display: inline-block;
			width: 30%;
		    margin-left: 20px;
		    vertical-align: top;
		    margin-top: 35px;
		}
	}
	@include mq(epic) {
		.contact-form {
		}
		p {
			width: 35%;
		    margin-left: 40px;
		}
	}
	
	
}
