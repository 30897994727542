// CONTENT


//// IN PAGE HEADER / NAVIGATION 


.article-nav {
	ul {
		@include listreset();
		
	}
	@include mq($to: tab) {
		
		.nav-menu-trigger,
		.contact,
		.about {
			display: none;
		}
		.tel {
			margin-bottom: $gutterwidth*2;
		}
	}
	.tel {
		a {
			@include font-size(20);
			font-weight: $bold;
			line-height: 45px;
			color: $textcolor;
			&:before {
				content: "";
				display: inline-block;
				background: url(../img/icons/icon-phone.png) center center no-repeat;
				background-size: 100%;
				width: 37px;
				height: 45px;
				position: relative;
				margin: 0 10px -13px 0;
			}
			@include mq(desk) {
				@include font-size(22);
			}
		}
	}
	
	@include mq(tab) {
		.show-nav-button {
			position: relative;
			display: inline-block;
			left: auto;
			top: 0;
			margin-left: -25px;
			span {
				display: none;
			}
			i {
				content: "+";
			}
		}
		.top-nav {
			text-align: right;
			li {
				display: inline-block;
				margin: 1px 0 0 4px;
			}
			.nav-menu-trigger {
				float: left;
				margin: 0;
			}
			a {
				color: $textcolor;
				font-weight: $bold;
			}
		}
	}
	@include mq(lap) {
		.top-nav {
			li {
				margin: 1px 0 0 15px;
			}
		}
	}
	@include mq(desk) {
		.top-nav {
			li {
				margin: 1px 0 0 25px;
			}
		}
	}
}



.article-header {

	@include mq($to: tab) {
		.primary-nav {
			display: none;

		}
		//RXB
		.careers-submenu {
			display:block;
			margin-bottom: 20px;
		}

	}	
	@include mq(tab) {
		padding-top: $gutterwidth*2;
	}
	@include mq(lap) {
		p {
			@include font-size(21);
		}
	}	
}

//// GENERAL PAGE CONTENT 

.text-content {
	
	h2 {
		margin-bottom: $gutterwidth;
		&:after {
			background: $grey;
			width: 100px;
			height: 1px;
			content: "";
			position: relative;
			display: block;
			margin-top: $gutterwidth;
		}
	}
	> p:first-of-type,
	.content-col > p:first-of-type {
		font-weight: $bold;
	}
	
	@include mq(desk) {
		max-width: 828px;
		.content-col {
			width: 65%;
			display: inline-block;
			vertical-align: top;
		}
		.share-col {
			width: 30%;
			margin-left: 4%;
			display: inline-block;
			vertical-align: top;
			padding-top: 10px;
		}
	}	
	
	ul {	
		@include listreset();
		margin-bottom: 1.4em;
		li {
			padding-left: 20px;
			margin-bottom: 0.5em;
			&:before {
				content: "–";
				margin-left: -20px;
				position: relative;
				float: left;
				@include rotate(-30deg);
			}
		}
	}
	@include mq(epic) {
		width: 72%;
		max-width: none;
	}
}


h1, h2, h3, h4, p, li {
	
	@include mq(epic) {
// 		max-width: 685px
	}

}


.article-image {
	margin: 0 0 $gutterwidth 0;

	@include mq(tab) {
//	    max-width: none;
//		width: calc(110% + 100px);
//		margin-left: -31%;
	}	
	@include mq(desk) {
//		margin-left: -28%;
//		width: calc(112% + 100px);
		max-height: 600px;
		overflow: hidden;
		img {
			margin-top: -10%;			
		}
	}	
}

.article-meta p {
	font-size: 110%;
	font-weight: $bold;
}

.share-links {
	padding: $gutterwidth 0;
	ul {
		@include listreset();
		li {
			padding-left: 25px;
		}
		.fa {
			margin-left: -25px;
			float: left;
			top: 3px;
			position: relative;
		}
	}
}

.cta {
	border-bottom: 1px solid $textcolor;
	display: inline-block;
	margin-top: $gutterwidth;
	p {
		margin-bottom: 0.8em;
	}
	.open-link {
		margin-left: $gutterwidth;
	}
}



//// RELATED PAGE TEXT & LINKS

.rel-pages {
	h2, h3 {
		border-top: 1px solid $textcolor;
		padding-top: $gutterwidth;
		margin-top: $gutterwidth;
		margin-right: 10px;
		a .open-collapse,
		a -open-link {
			float: right;
		}
	}
}



.article-header,
.rel-pages {
	@include mq(tab) {
		.primary-nav {
			margin-bottom: 40px;
			> li {
				margin-top: 0;
				padding: 10px 0;
			    vertical-align: bottom;
				a .open-collapse,
				a .open-link {
					float: right;
				}
			}
		}
	}	
}

.overview {
	h3 {
		a {
			font-weight: bold;
		}
	}
}

.jobs {
	margin-top: 40px;

	//div {
    //
	//	display: inline-block;
	//	width: 50%;
	//	float: left;
	//	min-height: 250px;
    //
    //
	//	@media (max-width: 740px) {
	//		float: none;
	//		width: 100%;
	//		display: block;
	//		padding-left: 0;
	//	}
    //
	//	h3 {
	//		font-weight: bold;
	//		margin-bottom: 5px;
	//	}
	//	p {
	//		font-weight: bold;
	//		margin-bottom: 10px;
	//	}
	//	a {
	//		font-weight: bold;
	//		text-decoration: underline;
	//	}
	//}
    //
	//div:nth-child(even) {
	//	padding-left: 35px;
    //
	//	@media (max-width: 740px) {
	//		padding-left: 0;
	//	}
	//}
    //
	//div:nth-child(odd) {
	//	padding-right: 5px;
    //
	//	@media (max-width: 740px) {
	//		padding-right: 0;
	//	}
	//}

}

.back {
	clear: both;
	display: block;
}