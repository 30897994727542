// BITS N PIECES

.open-collapse {
	width: 20px;
	height: 20px;
	display: inline-block;
	margin-left: 3px;
	top: 0px;
	position: relative;
	&:before,
	&:after {
		width: 11px;
		height: 1px;
		content: "";
		background-color: $textcolor;
		display: inline-block;
		position: absolute;
		top: 10px;
		@include transition();
	}
	&:before {
		@include rotate(-30deg);
		left: 0;
	}
	&:after {
		@include rotate(30deg);
		left: 9px;
	}
}
.show-sub-nav .open-collapse {
	&:before {
//		left: 9px;
		@include rotate(30deg);
	}
	&:after {
//		left: 0;
		@include rotate(-30deg);
	}
}

.open-link {
	width: 20px;
	height: 20px;
	display: inline-block;
	margin-left: 3px;
	top: -5px;
	position: relative;
	&:before {
		width: 14px;
		height: 1px;
		content: "";
		background-color: $textcolor;
		display: inline-block;
		@include rotate(-30deg);
		@include transition();
	}
	&-switch:before {
		@include rotate(30deg);
	}
}
.show-sub-nav .open-link:before {
	@include rotate(30deg);
}

h1 .open-collapse {
	width: 24px;
	height: 22px;
	&:before {
		width: 22px;
		height: 3px;
		@include rounded(10px);
	}
}

.fa {
	min-width: 20px;
}