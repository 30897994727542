//// GENERAL PAGE LAYOUTS


.full-page {
	@include mq(tab) {
	}
	
	.side-col {
		
		@include mq($to: tab) {
			display: inline-block;
			width: 100%;
			height: 200px;
			.stencil-top {
				display: none;
			}
			.stencil-image {
				display: inline-block;
				width: 100%;
				height: 190px;
				background: transparent url(../img/stencils/stencil-bg.jpg) center center no-repeat;
				background-size: 100%;
				.image {
					width: 100%;
					height: 200px;
					background-position: center center;
					background-repeat: no-repeat;
					background-size: cover;
				}
				.mask {
				    width: 100%;
				    height: 202px;
				    background-position: 0 2px;
				    background-repeat: no-repeat;
				    background-size: cover;
				    position: absolute;
				    top: 118px;
    					
				}
			}	
			@include mq(phab) {
				height: 300px;
				.stencil-image {
					height: 290px;
					.image {
						height: 300px;
					}
					.mask {
					    height: 302px;
					}
				}
			}	
		}
		@include mq(tab) {
	//		flex-grow: 40;
			z-index: 1;
			display: inline-block;
			vertical-align: top;
			width: calc(39% - 40px);
			position: relative;
		}
		@include mq(lap) {
			width: calc(33% - 60px);		
		}
	}
	
	.main-col {
		padding: $gutterwidth $gutterwidth;
		min-height: 100vh;
	
		@include mq(tab) {
			padding: $gutterwidth $gutterwidth*2 $gutterwidth;
			background: $white;
		//	flex-grow: 60;
			z-index: 2;
			display: inline-block;
			width: calc(60% + 40px);
			position: relative;
		}
		@include mq(lap) {
			width: calc(66% + 60px);		
			padding: $gutterwidth $gutterwidth*2 $gutterwidth $gutterwidth*3;
		}
		@include mq(desk) {
			width: calc(66% + 60px);		
			padding: $gutterwidth $gutterwidth*2 21px $gutterwidth*3;
		}
	}
	
	&--all-content {
		
		.side-col {
			@include mq($to: tab) {
				display: inline-block;	
				padding: $gutterwidth;
			}
			@include mq(tab) {
// 				margin-top: 260px;				
			}
		}
		@include mq(tab) {
			.text-content {
				padding-top: $gutterwidth;
			}
		}
		@include mq(lap) {
			.push-left {
				padding-left: 18%;
			}			
		}
		@include mq(desk) {
			.text-content {
				padding-top: $gutterwidth*2;
			}
		}
		@include mq(epic) {
			.text-content {
				padding-top: $gutterwidth*5;
			}
			.push-left {
				padding-left: 26%;
			}			
		}
	}
	
	&--wide-content {
		.side-col {
			
			@include mq($to: tab) {
			}
			@include mq(tab) {
				width: calc(24% - 40px);
			}
			@include mq(lap) {
				width: calc(34% - 60px);		
			}
		}
		.main-col {
			@include mq(tab) {
				width: calc(75% + 40px);
				padding-left: calc(15% + 40px);
				.pull-left {
					margin-left: calc(-15% - 80px);
				}			
			}
			@include mq(lap) {
				width: calc(65% + 60px);		
				padding-left: calc(15% + 60px);
			}
			@include mq(desk) {
				width: calc(65% + 60px);		
				padding-left: calc(15% + 60px);
			}
			
			
		}
	}
	&.homepage {
		.side-col {
			
			@include mq($to: tab) {
			}
			@include mq(tab) {
				width: calc(39% - 40px);
			}
			@include mq(lap) {
				width: calc(49% - 60px);		
			}
		}
		.main-col {
			@include mq(tab) {
				width: calc(60% + 40px);
//				padding-left: calc(15% + 40px);
				.pull-left {
//					margin-left: calc(-15% - 80px);
				}			
			}
			@include mq(lap) {
				width: calc(50% + 60px);		
//				padding-left: calc(15% + 60px);
			}
			@include mq(desk) {
				width: calc(50% + 60px);		
//				padding-left: calc(15% + 60px);
			}
			
			
		}
	}
}













