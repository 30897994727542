// FOOTER
ul.footer-contact {
  list-style: none;
  padding-left: 0;
  margin-top: 50px;

  li {
    display: inline-block;
    margin-right: 2%;
    width: 45%;

    i.fa {
      width:20px;
    }
  }
}