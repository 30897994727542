// SITE

@-webkit-viewport { 
	width : device-width; 
}

@-moz-viewport { 
	width : device-width; 
}

@-ms-viewport { 
	width : device-width; 
}

@-o-viewport { 
	width : device-width; 
}

@viewport { 
	width : device-width; 
}

html {
	background : $bodycolor;
	font-size : 62.5%;
	-webkit-overflow-scrolling : touch;
	-webkit-tap-highlight-color : lighten($basecolor, 75%);
	-webkit-text-size-adjust : 100%;
	-ms-text-size-adjust : 100%; 
}

body {
	margin : 0;
	padding : 0;
	width : 100%;
	background-color : transparent;
	font-family : $basefont;
	@include font-size();
	line-height : $baselineheight;
	color : $textcolor; 
	
	@include mq($to: tab) {
		padding-top: 120px;
	}
}




//// DEV MODULES

.flash {
	background: #89C625 url(/_assets/img/flash-bg.png) repeat center center;	
	text-align: center;
	color: $white;
	@include font-size(20);
	padding: 15px;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 99;
	a {
	}
	&.env-local {
		background-color: #89C625;
	}
	&.env-dev {
		background-color: #FFC416;
	}
	&.env-stage {
		background-color: $yellow;
	}
	&.env-prod {
		background-color: $red;
	}
}

section#debug {
	border-top: 1px solid $grey;
	background: $white;
	width: 100%;
    max-width: none;
    box-shadow: 0 3px 10px rgba(0,0,0,0.3) inset;
    margin: 0;
    .col-group {
	    max-width: 1200px;
	    margin: 10px auto;
    }
}

.edit-this a {
	font-size: 70%;
	background: $white;
	padding: 1px 8px;
	text-transform: uppercase;
	font-weight: $bold;
	color: $red;
	
}
h1 .edit-this {
	margin: 0 10px;
}


.alert {
	font-weight: bold;

	button {
		display: none;
	}
}