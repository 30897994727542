// TYPOGRAPHY


//// Headings

h1, h2, h3, h4, h5, h6 {
	margin : 0;
	font-family : $baseheadingfont;
	font-weight : $baseheadingfontweight;
	color : $baseheadingfontcolor;
	text-rendering : optimizelegibility; 
}

h1 small, h2 small {
	font-weight : normal;
	color : $lightgrey; 
}

h1, .h1 {
	margin-bottom : $baselineheight / 2;
	@include font-size(24);
	line-height : 1.2; 
}

h2, .h2 {
	margin-bottom : $baselineheight / 2;
	@include font-size(18);
	line-height : 1.2; 
	@include mq(lap) {
		@include font-size(24);		
	}
}

h3, .h3 {
	margin-bottom : $baselineheight / 1.5;
	@include font-size(20);
	line-height : 1.3; 
	font-weight: $regular;
}

h4, .h4 {
	margin-bottom : $baselineheight;
	@include font-size(18);
	line-height : 1.25; 
}

h5, .h5 {
	@include font-size(16);
	margin-bottom : $baselineheight; 
}

h6, .h6 {
	margin-bottom : $baselineheight;
	@include font-size(14);
	font-weight : normal;
	letter-spacing : 1px;
	text-transform : uppercase; 
}


//// Basic Page type

p, ol, ul, li, dl, address {
	margin-bottom : $baselineheight;
	@include font-size(15);
	line-height : $baselineheight; 
}

small {
	font-size: 0.8em; 
}



hr {
	display : block;
	margin : 1em 0;
	padding : 0;
	height : 1px;
	border : 0;
	border-top : none;
	border-bottom : none; 
}

//// Lists

ul, ol {
	margin : 0 0 $baselineheight 0;
	padding : 0 0 0 $gutterwidth;
	
	li {
	}
}


//// blockquote

blockquote  {
	margin : 0 0 $baselineheight #{-$gutterwidth};
	padding-left : $gutterwidth;
	border-left : 2px solid $bordercolor;
	font-family : $altfont;
	font-style : normal; 
}

q {
	quotes : none; 
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content : '';
	content : none; 
}

cite {
	font-style : normal; 
}

//// definition lists

dl,
dd {
	margin-bottom : $baselineheight;  
}

dt {
	font-weight : $bold;
	text-transform: uppercase;
}

.dl-horizontal {

	dt {
		float : left;
		clear : left;
		width : 20.25%;
		text-align : right; 
	}
	
	dd {
		margin-left : 22.78%; 
	}
}

//// text-level

abbr[title] {
	border-bottom : 1px dotted $bordercolor;
	cursor : help; 
}

b, strong {
	font-weight : $bold; 
}
i, em {
	font-style: normal;
}

dfn {
	font-style : italic; 
}

ins {
	background-color : lighten($basecolor, 75%);
	color : $textcolor;
	text-decoration : none; 
}

mark {
	background-color : lighten($basecolor, 75%);
	color : $textcolor;
	font-style : italic;
	font-weight : $bold; 
}

pre,
code,
kbd,
samp {
	font-family : $codefont;
	@include font-size(14);
	line-height : $baselineheight; 
}

pre {
	white-space : pre;
	white-space : pre-wrap;
	word-wrap : break-word; 
}

sub,
sup {
	position : relative;
	@include font-size(12);
	line-height : 0;
	vertical-align : baselineheight; 
}

sup {
	top : -.5em; 
}

sub {
	bottom : -.25em; 
}



//// LINKS

a {
	text-decoration : none;
	color : $linkcolor;
	@include transition();
	
	&:visited {
		color : $linkcolorvisited; 
	}
	
	&:hover {
		text-decoration : none;
		color : $linkcolorhover !important; 
	}
		
	&:focus {
		outline : thin dotted;
		color : $linkcolorfocus; 
	}
	
	&:hover,
	&:active {
		outline : 0; 
	}
}
