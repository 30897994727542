// SIDE COL


.border { // LIGHT tone block
	width: 0px;
	@include mq(tab) {
		width: 20px;
	}
	@include mq(lap) {
		width: 40px;
	}
	@include mq(desk) {
		width: 60px;
	}
	@include mq(epic) {
		width: 80px;
	}
	height: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
	&:before,
	&:after  {
		width: 100%;
		height: 33%;
		left: 0;
		position: absolute;
		content: "";
		z-index: 1;
		display: block;
	}
	&:before { // MED tone block
		opacity: $md;
	}
	&:after { // DARKEST tone block
		opacity: $dk;
	}

	&-d-m-l {  // Home
		&:before {
			top: 33%
		}
		&:after {
			top: 0;
		}			
	}
	&-d-l-m { // Overview
		&:before {
			bottom: 0;
		}
		&:after {
			top: 0;
		}			
	}
	&-l-d-m { // Landing Page
		&:before {
			bottom: 0;
		}
		&:after {
			top: 34%;
		}			
	}
	&-l-m-d {  // Content Page
		&:before {
			top: 34%;
		}
		&:after {
			bottom: 0;
		}			
	}
	&-m-d-l { // Grid Layout Page
		&:before {
			top: 0;
		}
		&:after {
			top: 33%;
		}			
	}
	&-m-l-d {  // Article / Profile Page
		&:before {
			top: 0;
		}
		&:after {
			bottom: 0;
		}			
	}
	
	&-red {
		@include tint($red, $lt);
		&:before, &:after {
			background-color: $red;
		}	
	}
	&-black {
		@include tint($darkgrey, $lt);
		&:before, &:after {
			background-color: $darkgrey;
		}	
	}
	&-yellow {
		@include tint($yellow, $lt);
		&:before, &:after {
			background-color: $yellow;
		}	
	}
	&-ash {
		@include tint($ash, $lt);
		&:before, &:after {
			background-color: $ash;
		}	
	}
	&-green {
		@include tint($green, $lt);
		&:before, &:after {
			background-color: $green;
		}	
	}
	&-blue {
		@include tint($blue, $lt);
		&:before, &:after {
			background-color: $blue;
		}	
	}
	&-brown {
		@include tint($brown, $lt);
		&:before, &:after {
			background-color: $brown;
		}	
	}
	
}





.stencil {
	display: none;
	@include mq(tab) {
		display: block;
		position: fixed;
		bottom: 0;
		left: 20px;
		width: 50%;
//		min-width: 550px;
//		height: 60%;
//		padding-bottom: 60%;
//		background: rgba(0,0,0,0.1);
	    overflow: hidden;
	    max-height: 560px;
		.mask {
			display: inline-block;
			position: absolute;
			z-index: 2;
// 			content: "";
			position: relative;
			opacity: 1.0;
//			opacity: 0.8;
			top: 0;
			left: 0%;
			width: 100%;
			height: auto;
		}
		.image {
			position: absolute;
			width: 90%;
			z-index: 1;
		    top: 4%;
		    right: 0%;
		    width: 100%;
		    height: auto;
//		    max-width: 310px;
		}
		&-home {
		}
	}
	@include mq(lap) {
	    max-height: 610px;
		left: 40px;
		@media (min-height: 500px) {
		    max-height: 260px;		
		}
		@media (min-height: 600px) {
		    max-height: 360px;		
		}
		@media (min-height: 700px) {
		    max-height: 460px;		
		}
	}
	@include mq(desk) {
	    max-height: 300px;
		@media (min-height: 650px) {
		    max-height: 350px;		
		}
		@media (min-height: 850px) {
		    max-height: 500px;		
		}
		@media (min-height: 1060px) {
		    max-height: 700px;
		}
	}
	@include mq(epic) {
	    max-height: 300px;
		@media (min-height: 650px) {
		    max-height: 400px;		
		}
		@media (min-height: 850px) {
		    max-height: 600px;		
		}
		@media (min-height: 1060px) {
		    max-height: 800px;
		}
	}
	
	&-one {
		.image {
			width: 70%;
			right: auto;
			left: 0;
		}
	}
	&-news-article {
		.mask {
			@include mq(lap) {		
				width: 80%;
			}
			@include mq(desk) {		
				width: 70%;
			}
			@include mq(epic) {		
				width: 55%;
			}
		}
	}
	&-slash:after {
		content: "";
		display: inline-block;
		position: absolute;
		top: 10%;
		right: 0;
		width: 10%;
		height: auto;
		background: url(../img/stencils/stencil-slash.png) center center no-repeat;
	}
	
	&-top {
		.mask {
		}
		@include mq($to:lap) {
			display: none;			
		}
		@include mq(lap) {
			background: #333;
			width: 13%;
			min-width: 0;
			max-width: 30px;
			height: 0px;
			position: absolute;
			top: 0;
			left: 70%;
		    padding-bottom: 4.9%;
		    @include mq(epic) {
		    	width: 30px;
		    	padding-bottom: 26px;
		    }
		}
	}
}




.stencil-image,
.stencil-texture {
	display: none;
		
	@include mq($to: tab) {
		
	}
	@include mq(tab) {
		display: block;
		position: fixed;
		bottom: 0;
		left: 20px;
		width: 35%;
//		height: 60%;
//		padding-bottom: 60%;
//		background: rgba(0,0,0,0.1);
	    overflow: hidden;
	    height: 35vh;
		.texture {
			display: inline-block;
			position: absolute;
			z-index: 2;
// 			content: "";
			position: relative;
			opacity: 1.0;
//			opacity: 0.8;
			top: 0;
			left: 0%;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-position: left center;
			background-size: cover;
		}
		.mask {
			display: inline-block;
			position: absolute;
			z-index: 2;
// 			content: "";
			position: relative;
			opacity: 1.0;
//			opacity: 0.8;
			top: 0;
			left: 0%;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-position: left center;
			background-size: cover;
		}
		.image {
			position: absolute;
			width: 90%;
			z-index: 1;
		    top: 0;
		    left: 0%;
		    width: 100%;
			height: 100%;
//		    max-width: 310px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
		}
	}
	@include mq(lap) {
		left: 40px;
	}
	@include mq(desk) {
		left: 60px;
	}
	@include mq(epic) {
		left: 80px;
	}
}

.stencil-texture {
	bottom: 35vh;
    height: 17.8vh;
}


@include mq(tab) {
	.full-page.homepage {
		.stencil-image {
			width: 45%;
			height: 70vh;
			max-height: 600px;
			.mask {
				
			}
			.image {
				left: auto;
				top: 5px;
				right: 0;
				width: 90%;
				height: 55%;
			}
		}
		@include mq(desk) {
			.stencil-image {
				height: 80vh;
				max-height: 700px;
			}
		}
		
		@include mq(epic) {
			.stencil-image {
				height: 90vh;
				max-height: 800px;
			}
		}
		
		.stencil-top {
			max-width: 46px;
		}
	}
}


.side-image {
	display: inline-block;
    overflow: hidden;
    margin: -45px -20px -25px;
    .image {
		position: relative;
		width: 90%;
		z-index: 1;
	    width: 100%;
	    height: auto;
//		    max-width: 310px;
	    margin: 0;
	}
	figcaption {
		display: none;
	}
	@include mq(tab) {
		position: fixed;
		top: 260px;
	    left: 40px;
	    width: 34%;
	}
	@include mq(lap) {
		left: 60px;
	    width: 36%;
	    .image {
		    margin-left: -20%;
	    }
	}
	@include mq(desk) {
//		top: 280px;
		top: auto; 
		left: 80px;
	    width: 39%;
	    bottom: 0;
	}
	@include mq(epic) {
//		top: 300px;
		left: 100px;
	    width: 40%;
	}

}











