// BROWSER



.no-flexbox {
	
	@include mq(tab) {
		.general-content .fi img {
			position: relative;
			bottom: auto;
		}
		.mainnav  > .fi {
			display: inline-block;
			width: 43%;
			vertical-align: top;
		}
		.mainnav > ul.fi {
			width: 63%;
		}
 
	}
	
	@include mq(lap) {
		.general-content > .fi {
			display: inline-block;
			width: 43%;
			vertical-align: top;
		}
	}
	
}