// HOMEPAGE

.full-page.homepage {
	.article-header p strong {
		@include font-size(24);
		font-weight: 500;
	}
}

.homepage-intro {
	@include mq(lap) {		
		> p:first-of-type {
			@include font-size(20);
		}
	}
}

.homepage-specialist-sections {
	h3 {
		@include font-size(24);
		font-weight: 500;
	}
	p {
		@include font-size(14);
		color: $grey;
		max-width: 400px;
	}
	.homepage-section-link {
		max-width: 200px;
		display: inline-block;
		border-bottom: 1px solid $red;
		margin-bottom: 10px;
		padding-bottom: 5px;
	}
}