//// LAYOUT ANIMATIONS 

.content-header {
	&.fadein {
		@include fadein(up);
	}
	@include mq(lap) {
		&.fadein {
			@include fadein(left);
		}
	}	
}
.flexcol-adaptive {
	.fadein {
		@include fadein(up);
	}
	@include mq(lap) {
		.fadein {
			@include fadein(left);
			&:nth-of-type(1) {
				-webkit-animation-delay: $animationDelay;
				animation-delay: $animationDelay;				
			}
			&:nth-of-type(2) {
				-webkit-animation-delay: $animationDelay+($animationInc);
				animation-delay: $animationDelay+($animationInc);				
			}
			&:nth-of-type(3) {
				-webkit-animation-delay: $animationDelay+($animationInc*2);
				animation-delay: $animationDelay+($animationInc*2);
			}
			&:nth-of-type(4) {
				-webkit-animation-delay: $animationDelay+($animationInc*3);
				animation-delay: $animationDelay+($animationInc*3);				
			}
		}
	}	
}

.page-footer .flexcol {
	.fadein {
		@include fadein(up);
	}
	@include mq(lap) {
		.fadein {
			@include fadein(up);
			&:nth-of-type(1) {
				-webkit-animation-delay: $animationDelay;
				animation-delay: $animationDelay;				
			}
			&:nth-of-type(2) {
				-webkit-animation-delay: $animationDelay+($animationInc);
				animation-delay: $animationDelay+($animationInc);				
			}
			&:nth-of-type(3) {
				-webkit-animation-delay: $animationDelay+($animationInc*2);
				animation-delay: $animationDelay+($animationInc*2);
			}
			&:nth-of-type(4) {
				-webkit-animation-delay: $animationDelay+($animationInc*3);
				animation-delay: $animationDelay+($animationInc*3);				
			}
		}
	}	
}
.flexcol-double {
	overflow: hidden;
	.align-right.fadein {
		@include fadein(up);
	}
}

.flexcol-full,
.image-gallery {
	.fadein {
		@include fadein(fade);
	}

	@include mq(phab) {
		.image-grid .fadein {
			@include fadein(fade);
			&:nth-of-type(1) {
				-webkit-animation-delay: $animationDelay;
				animation-delay: $animationDelay;				
			}
			&:nth-of-type(2) {
				-webkit-animation-delay: $animationDelay+($animationInc);
				animation-delay: $animationDelay+($animationInc);				
			}
			&:nth-of-type(3) {
				-webkit-animation-delay: $animationDelay+($animationInc*2);
				animation-delay: $animationDelay+($animationInc*2);
			}
			&:nth-of-type(4) {
				-webkit-animation-delay: $animationDelay+($animationInc*3);
				animation-delay: $animationDelay+($animationInc*3);				
			}
		}
	}
}


