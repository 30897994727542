// VARIABLES


//// Monochrome

$pureblack: #000;
$black: #1a1819;
$darkgrey: lighten($pureblack, 35%);
$grey: lighten($pureblack, 50%);
$midgrey: lighten($pureblack, 62%);
$lightgrey: lighten($pureblack, 75%);
$lightergrey: lighten($black, 85%);
$offwhite: #f0f0f1;
$white: rgb(255,255,255);

//// Glorious technicolour

$red: #cf5c59;
$yellow: #b69d34;
$ash: #9baba5;
$green: #4e7f40;
$blue: #697fa2;
$brown: #8a6c6e;

$ared: #e25946;

$orange: #e9982d;
$purple: #773d9c;


$basecolor: rgb(45,53,62);
$compcolor: adjust-hue($basecolor, 180);
$bordercolor: lighten($basecolor, 60%);


$errorcolor: $red;
$warningcolor: $orange;
$alertcolor: $yellow;
$successcolor: $green;
$infocolor: $blue;

$facebook: #3b5998;
$twitter: #00aeef;
$googleplus: #cb4437;
$instagram: #b8a091;

//// Colour opacities
$dk: 0.75;
$md: 0.50;
$lt: 0.25;


//// Type


$sans:  "Helvetica Neue", Helvetica, Arial, sans-serif;
$sansbold:  "Helvetica Neue", Helvetica, Arial, sans-serif;

$serif: Georgia, Times, "Times New Roman";
$mono: 'Roboto Mono', Monaco, Courier New, monospace;


//// Type weights
$light: 100;
$regular: 400;
$semibold: 500;
$bold: 700;




// BASE   

$bodycolor: $offwhite;
$textcolor: $black;

//// Typography

$basefont: $sans;
$basefontsize: 16;
$baselineheight: 1.4em;
$baseheadingfont: inherit;
$baseheadingfontweight: $bold;
$baseheadingfontcolor: inherit;

$altfont: $serif;
$altlineheight: 1.35;

$codefont: $mono;

//// Links

$linkcolor: $basecolor;
$linkcolorhover: $textcolor;
$linkcolorvisited: darken($linkcolorhover, 10);
$linkcolorfocus: darken($linkcolorvisited, 10);


// ELEMENTS 


//// animations

$animationSpeed : 0.8s;
$animationDelay : 0.4s;
$animationInc : 0.2s;
$moveDistance: 50px;


//// buttons

$buttonbackground: $basecolor;
$buttonbordercolor: darken($buttonbackground, 10%);
$buttoncolor: $white;
$buttonborderwidth: 1px;
$buttonborderstyle: solid;


//// tables

$tablebackground: transparent;
$tablestripe: lighten($basecolor, 60%);
$tablehover: lighten($basecolor, 50%);
$tableborder: lighten($basecolor, 40%);
$tableborderwidth: 1px;
$tableborderstyle: solid;

//// forms

$inputbackground: $white;
$inputborder: lighten($basecolor, 40%);
$inputborderwidth: 1px;
$inputborderstyle: solid;
$inputhover: lighten($basecolor, 20%);
$inputfocus: $basecolor, 40%;
$placeholdercolor: $lightgrey;



// LAYOUTS 	

$baseline: $baselineheight;
$gutterwidth: 20px;

