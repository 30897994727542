// Gets all the partials in one place, independent of stylesheet, browser and device

// Variables and mixins
@import "pre/variables";
@import "pre/mixins";
@import "pre/mq"; 					//// @media query handler


// Base 
@import "base/reset";
@import "base/icomoon";
// @import "base/font-awesome";		//// prob best just sticking to one font icon library
@import "base/typography";


// Addons
@import "addons/animate";


// Page Elements
@import "elements/gradients";
@import "elements/buttons";
@import "elements/forms";
@import "elements/tables";
@import "elements/lists";
@import "elements/colours";
@import "elements/media";
@import "elements/bits-n-pieces";


// Page Layouts
@import "layouts/site";
@import "layouts/header";
@import "layouts/nav";
@import "layouts/footer";
@import "layouts/general";
@import "layouts/contact-form";
@import "layouts/homepage";
@import "layouts/amputation_page"; 
@import "layouts/content";
@import "layouts/lists";
@import "layouts/side-col";



@import "layouts/animations";


// Browsers Fixes
@import "layouts/browser";



// Other @media styles

//// 2x
@media 
only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5) {
	@import "other/2x"; 
}

//// Print
@media print {
	@import "other/print";
}