// MIXINS

// BASE


@mixin tint($color, $tint) {
	background-color: rgba($color, $tint);
}


//// Typography

@mixin font-size($font-size: 16){
font-size : #{$font-size}px;
font-size : #{$font-size / 10}rem; }


@mixin text-overflow() {
overflow : hidden;
text-overflow : ellipsis;
white-space : nowrap; }

//// @include text-shadow(HORIZONTAL VERTICAL BLUR COLOR))
@mixin text-shadow($shadow: 0 1px 2px rgba(0,0,0,.25)) {
-webkit-text-shadow : $shadow;
-moz-text-shadow : $shadow;
text-shadow : $shadow; }


//// Links



//// FLEX COLS

@mixin flexcol($min-width, $gutter: 0, $last-equal: false, $max-cols: 4) {
	display: flex;
	flex-wrap: wrap;
	margin-left: -$gutter;
//	margin-bottom: -$gutter;

    > *, .fc {
      flex: 1 0 $min-width;
      margin-left: $gutter;
      margin-bottom: $gutter;

      @if $last-equal {
        @for $i from 2 through $max-cols {
          $screen-width: ($min-width*$i)+($gutter*$i);
          $column-width: (100%/$i);
          @media (min-width: $screen-width) {
            max-width: calc(#{$column-width} - #{$gutter});
          }
        }
    
        $column-width: (100%/$max-cols);
        @media (min-width: $min-width*$max-cols) {
            min-width: calc(#{$column-width} - #{$gutter});
        }
      }
    }
}



// ELEMENTS 


//// ANIMATIONS

@mixin fadein($type : fade, $speed : normal) {
	@if $type == left {
		-webkit-animation-name: fadeInLeft;
		animation-name: fadeInLeft;
	}
	@else if $type == right {
		-webkit-animation-name: fadeInRight;
		animation-name: fadeInRight;
	}
	@else if $type == up {
		-webkit-animation-name: fadeInUp;
		animation-name: fadeInUp;
	}
	@else if $type == upbig {
		-webkit-animation-name: fadeInUpBig;
		animation-name: fadeInUpBig;
	}
	@else if $type == down {
		-webkit-animation-name: fadeInDown;
		animation-name: fadeInDown;
	}
	@else if $type == zoom {
		-webkit-animation-name: zoomIn;
		animation-name: zoomIn;
	}
	@else if $type == fade {
		-webkit-animation-name: fadeIn;
		animation-name: fadeIn;
	}
	@if $speed == normal {
	  -webkit-animation-duration: $animationSpeed;
	  animation-duration: $animationSpeed;		
	}
	@else if $speed == slow {
	  -webkit-animation-duration: $animationSpeed*3;
	  animation-duration: $animationSpeed*3;		
	}
	@else if $speed == fast {
	  -webkit-animation-duration: $animationSpeed/2;
	  animation-duration: $animationSpeed/2;		
	}

	-webkit-animation-delay: $animationDelay;
	animation-delay: $animationDelay;				

}



//// Lists {
@mixin listreset($margins: 0) {
	list-style: none;
	clear: both;
	float: none;
	margin-bottom: $margins;
	padding: 0;
	li {
		margin-bottom: 0;
	}
}


@mixin befa() {
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;		
	z-index: 0;
	content: "";
}

//// gradients

@mixin horizontal($startColor : $white, $endColor : $lightergrey) {
background-color: $endColor;
background-image  : -webkit-gradient(linear, 0 0, 100% 0, from($startColor), to($endColor)); // Safari 4+, Chrome 2+
background-image  : -webkit-linear-gradient(left, $startColor, $endColor); 					 // Safari 5.1+, Chrome 10+
background-image  : -moz-linear-gradient(left, $startColor, $endColor); 					 // FF 3.6+
background-image  : -ms-linear-gradient(left, $startColor, $endColor); 						 // IE10
background-image  : -o-linear-gradient(left, $startColor, $endColor); 						 // Opera 11.10
background-image  : linear-gradient(left, $startColor, $endColor); 							 // W3C
background-repeat : repeat-x; }

@mixin vertical($startColor : $white, $endColor:  $lightergrey) {
background-image  : -webkit-gradient(linear, 0 0, 0 100%, from($startColor), to($endColor)); // Safari 4+, Chrome 2+
background-image  : -webkit-linear-gradient(top, $startColor, $endColor); 					 // Safari 5.1+, Chrome 10+
background-color  : $endColor;
background-image  : -moz-linear-gradient(top, $startColor, $endColor);   					 // FF 3.6+
background-image  : -ms-linear-gradient(top, $startColor, $endColor); 						 // IE10
background-image  : -o-linear-gradient(top, $startColor, $endColor); 						 // Opera 11.10
background-image  : linear-gradient(top, $startColor, $endColor); 							 // W3C
background-repeat : repeat-x; }

@mixin directional($startColor : $white, $endColor : $lightergrey, $deg : 45deg) {
background-color  : $endColor;
background-image  : -moz-linear-gradient($deg, $startColor, $endColor); 					// FF 3.6+
background-image  : -ms-linear-gradient($deg, $startColor, $endColor); 						// IE10
background-image  : -webkit-linear-gradient($deg, $startColor, $endColor); 					// Safari 5.1+, Chrome 10+
background-image  : -o-linear-gradient($deg, $startColor, $endColor); 						// Opera 11.10
background-image  : linear-gradient($deg, $startColor, $endColor); 							// W3C
background-repeat : repeat-x; }

//// .bordered(COLOR, COLOR, COLOR, COLOR);

@mixin bordered($top-color: #eee, $right-color: #eee, $bottom-color: #eee, $left-color: #eee) {
border-top : solid 1px $top-color;
border-left : solid 1px $left-color;
border-right : solid 1px $right-color;
border-bottom : solid 1px $bottom-color; }

//// @include rounded(VALUE);

@mixin rounded($radius:4px) {
-webkit-border-radius : $radius;
-moz-border-radius : $radius;
border-radius : $radius; }

//// @include border-radius(VALUE,VALUE,VALUE,VALUE);

@mixin border-radius($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
-webkit-border-top-right-radius : $topright;
-webkit-border-bottom-right-radius : $bottomright;
-webkit-border-bottom-left-radius : $bottomleft;
-webkit-border-top-left-radius : $topleft;
-moz-border-radius-topright : $topright;
-moz-border-radius-bottomright : $bottomright;
-moz-border-radius-bottomleft : $bottomleft;
-moz-border-radius-topleft : $topleft;
border-top-right-radius : $topright;
border-bottom-right-radius : $bottomright;
border-bottom-left-radius : $bottomleft;
border-top-left-radius : $topleft;
-webkit-background-clip : padding-box;
-moz-background-clip : padding;
background-clip : padding-box; }

//// @include box-shadow(HORIZONTAL VERTICAL BLUR COLOR))

@mixin box-shadow($shadow: 0 1px 3px rgba(0,0,0,.25)) {
-webkit-box-shadow : $shadow;
-moz-box-shadow : $shadow;
box-shadow : $shadow; }

//// .drop-shadow(HORIZONTAL, VERTICAL, BLUR, ALPHA);

@mixin drop-shadow($x-axis: 0, $y-axis: 1px, $blur: 2px, $alpha: 0.1) {
-webkit-box-shadow : $x-axis $y-axis $blur rgba(0, 0, 0, $alpha);
-moz-box-shadow : $x-axis $y-axis $blur rgba(0, 0, 0, $alpha);
box-shadow : $x-axis $y-axis $blur rgba(0, 0, 0, $alpha); }

//// @include opacity(VALUE);

@mixin opacity($opacity : .5) {
-webkit-opacity : $opacity;
-moz-opacity : $opacity;
opacity : $opacity; }

//// .rotate(VALUEdeg);

@mixin rotate($deg) {
-webkit-transform : rotate($deg);
-moz-transform : rotate($deg);
-ms-transform : rotate($deg);
-o-transform : rotate($deg);
transform : rotate($deg); }

//// .scale(VALUE);

@mixin scale($ratio) {
-webkit-transform : scale($ratio);
-moz-transform : scale($ratio);
-ms-transform : scale($ratio);
-o-transform : scale($ratio);
transform : scale($ratio); }

//// .skew(VALUE, VALUE);

@mixin skew($x: 0, $y: 0) {
-webkit-transform : skew($x, $y);
-moz-transform : skew($x, $y);
-ms-transform : skew($x, $y);
-o-transform : skew($x, $y);
transform : skew($x, $y); }

//// @include transition(PROPERTY DURATION DELAY(OPTIONAL) TIMING-FINCTION);

@mixin transition($transition: all 0.3s ease-in-out) {
-webkit-transition : $transition;
-moz-transition : $transition;
-ms-transition : $transition;
-o-transition : $transition;
transition : $transition; }

//// .translate(VALUE, VALUE)

@mixin translate($x: 0, $y: 0) {
-webkit-transform : translate($x, $y);
-moz-transform : translate($x, $y);
-ms-transform : translate($x, $y);
-o-transform : translate($x, $y);
transform : translate($x, $y); }

@mixin translate3d($x: 0, $y: 0, $z: 0) {
-webkit-transform : translate($x, $y, $z);
-moz-transform : translate($x, $y, $z);
-ms-transform : translate($x, $y, $z);
-o-transform : translate($x, $y, $z);
transform : translate($x, $y, $z); }

//// buttons

//// @include background-alpha(VALUE VALUE);

@mixin background-alpha($color: $white, $alpha: 1) {
background-color : hsla(hue($color), saturation($color), lightness($color), $alpha); }

//// .background-size(VALUE VALUE);

@mixin background-size($size){
-webkit-background-size : $size;
-moz-background-size : $size;
-o-background-size : $size;
background-size : $size; }

//// .background-clip(VALUE); (border-box, padding-box, content-box)

@mixin background-clip($clip) {
-webkit-background-clip : $clip;
-moz-background-clip : $clip;
background-clip : $clip; }

//// @include box-sizing(VALUE); (border-box, padding-box, content-box)

@mixin box-sizing($boxsize: border-box) {
-webkit-box-sizing : $boxsize;
-moz-box-sizing : $boxsize;
-ms-box-sizing : $boxsize;
box-sizing : $boxsize; }

//// Chrome Frame

@mixin chromeframe() {
margin : .2em 0;
background : $lightgrey;
color : $black;
padding : .2em 0; }

//// Contain floats

@mixin clearfix {
*zoom : 1;
&:before,
&:after {
display: table;
content : ""; }
&:after {
clear : both; } }

//// For image replacement

@mixin hide-text() {
text-indent : 100%;
white-space : nowrap;
overflow : hidden; }

//// Hide from visual and speaking browsers

@mixin hidden() {
display : none !important;
visibility : hidden; }

//// Hide but maintain layout

@mixin invisible() {
visibility : hidden; }

//// @include resize(VALUE) (none, both, horizontal, vertical, inherit)

@mixin resize($direction: both) {
resize : $direction;
overflow : auto; }

//// .userselect(VALUE) (all, element, none, text)

@mixin user-select($select) {
-webkit-user-select : $select;
-moz-user-select : $select;
-o-user-select : $select;
user-select : $select; }

//// Hidden but available to speaking browsers

@mixin visuallyhidden() {
overflow : hidden;
position : absolute;
clip : rect(0 0 0 0);
height : 1px;
width : 1px;
margin : -1px;
padding : 0;
border : 0;
//// Make visuallyhidden focusable with a keyboard

&.focusable {

&:active,
&:focus {
position : static;
clip : auto;
height : auto;
width : auto;
margin : 0;
overflow: visible; } } }

// LAYOUTS

//// .columns(250px, 0, 24px, COLOR, solid, 1px)

@mixin columns($colwidth: 250px, $colcount: 0, $colgap: 50px, $columnRuleColor: #eee, $columnRuleStyle: solid, $columnRuleWidth: 1px) {
-webkit-column-width : $colwidth;
-webkit-column-count : $colcount;
-webkit-column-gap : $colgap;
-webkit-column-rule-color : $columnRuleColor;
-webkit-column-rule-style : $columnRuleStyle;
-webkit-column-rule-width : $columnRuleWidth;
-moz-column-width : $colwidth;
-moz-column-count : $colcount;
-moz-column-gap : $colgap;
-moz-column-rule-color : $columnRuleColor;
-moz-column-rule-style : $columnRuleStyle;
-moz-column-rule-width : $columnRuleWidth;
column-width : $colwidth;
column-count : $colcount;
column-gap : $colgap;
column-rule-color : $columnRuleColor;
column-rule-style : $columnRuleStyle;
column-rule-width : $columnRuleWidth; }

