// 7 COLOURS

/*
	Personal injury - brown
	Medical negligence	- ash
	Accidental claims advice - blue
	Financial misselling  - green
	About clear law - red
	News - black
	Case studies - yellow
*/



.page-colour-brown,
.page-body .accent-brown {
	
	// color:
	h1, a, .accent, em {
		color: $brown;
	}

	.border {
		background-color: rgba($brown, 0.25);
		&:before, &:after {
			background-color: $brown;
		}
	}
	
	// background-color:
	.stencil-top,
	.open-link:before,
	.open-collapse:before,
	.open-collapse:after {
		background-color: $brown;
	}

	// border-color:
	border-color: $brown;

	.cta {
		border-color: $brown;	
	}

}

.page-colour-ash,
.page-body .accent-ash {
	
	// color:
	h1, a, .accent, em {
		color: $ash;
	}
	
	.border {
		background-color: rgba($ash, 0.25);
		&:before, &:after {
			background-color: $ash;
		}
	}
	// background-color:
	.stencil-top,
	.open-link:before,
	.open-collapse:before,
	.open-collapse:after {
		background-color: $ash;
	}

	// border-color:
	border-color: $ash;

	.cta {
		border-color: $ash;	
	}

}

.page-colour-blue,
.page-body .accent-blue {
	
	// color:
	h1, a, .accent, em {
		color: $blue;
	}
	
	.border {
		background-color: rgba($blue, 0.25);
		&:before, &:after {
			background-color: $blue;
		}
	}
		
	// background-color:
	.stencil-top,
	.open-link:before,
	.open-collapse:before,
	.open-collapse:after {
		background-color: $blue;
	}

	// border-color:
	border-color: $blue;

	.cta {
		border-color: $blue;	
	}

}

.page-colour-green,
.page-body .accent-green {
	
	// color:
	h1, a, .accent, em {
		color: $green;
	}
	
	.border {
		background-color: rgba($green, 0.25);
		&:before, &:after {
			background-color: $green;
		}
	}
	// background-color:
	.stencil-top,
	.open-link:before,
	.open-collapse:before,
	.open-collapse:after {
		background-color: $green;
	}

	// border-color:
	border-color: $green;
	
	.cta {
		border-color: $green;	
	}

}

.page-colour-red,
.page-body .accent-red {
	
	// color:
	h1, a, .accent, em {
		color: $red;
	}
	
	.border {
		background-color: rgba($red, 0.25);
		&:before, &:after {
			background-color: $red;
		}
	}
	// background-color:
	.stencil-top,
	.open-link:before,
	.open-collapse:before,
	.open-collapse:after {
		background-color: $red;
	}

	// border-color:
	border-color: $red;

	.cta {
		border-color: $red;	
	}

}


.page-colour-black,
.page-body .accent-black {
	
	// color:
	h1, a, .accent, em {
		color: $darkgrey;
	}
	
	.border {
		background-color: rgba($darkgrey, 0.25);
		&:before, &:after {
			background-color: $darkgrey;
		}
	}
	// background-color:
	.stencil-top,
	.open-link:before,
	.open-collapse:before,
	.open-collapse:after {
		background-color: $darkgrey;
	}

	// border-color:
	border-color: $darkgrey;

	.cta {
		border-color: $darkgrey;
	}

}

// To set content page border as grey regardless of page level colour
.page-body .border.border-black {
	background-color: rgba($darkgrey, 0.25);
	&:before, &:after {
		background-color: $darkgrey;
	}
}

.page-colour-yellow,
.page-body .accent-yellow {
	
	// color:
	h1, a, .accent, em {
		color: $yellow;
	}
	
	.border {
		background-color: rgba($yellow, 0.25);
		&:before, &:after {
			background-color: $yellow;
		}
	}
	// background-color:
	.stencil-top,
	.open-link:before,
	.open-collapse:before,
	.open-collapse:after {
		background-color: $yellow;
	}

	// border-color:
	border-color: $yellow;

	.cta {
		border-color: $yellow;	
	}

}

