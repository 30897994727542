// Lists

// list-bordered

.list-bordered {
list-style-type : none;
margin : 0 0 $baselineheight 0;
padding : 0;

	li {
		margin-bottom : $baselineheight / 4;
		padding-bottom : $baselineheight / 4;
		border-bottom : 1px dashed $bordercolor;
	}
	
	li:last-child {
		margin-bottom : 0;
		padding-bottom : 0;
		border-bottom-width : 0; 
	}
}




//// Specific Elements

.link-list {
	li {
		color: red;
		
	    @include mq($from: tab) {
	        color: green;
		}
	    @include mq($from: lap) {
	        color: blue;
		}
	}
}